.header-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 80px;
  background: #000000;
  z-index: -1;
}

.dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 80px);
  background: #000000;

  &-img {
    width: 1000px;
    max-width: 100vw;
  }
}