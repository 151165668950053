:root {
  --white-color: #FFFFFF;
  --lightgrey-color: #CCCCCC;
  --grey-color: #707070;
  --black-color: #3E3E3E;
  --blue-color: #2A52A4;
  --green-color: #007E2A;
  --red-color: #FF0000;
  --border-color: #ECECEC;
}

body {
  animation: fade-in 1.2s;
  transition-delay: .01s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background: var(--blue-color);
  border-radius: 30px;
  border: 1px solid var(--border-color);
  height: 157px;
}

.dropdown-menu {
  padding: 10px 30px;
  &.active {
    color: rgb(0, 111, 238);
    font-weight: 700;
  }
  .title {

  }
}

@media screen and (min-width: 1280px) {
  .showfer-admin {
    .hidden-menu, .header-user, .header-hamburger {
      display: none;
    }
  }
}

@media screen and (max-width: 1280px) {
  .ant-dropdown-menu-title-content {
    .active {
      color: #ffa500!important;
    }
  }

  .showfer-admin {
    .header {
      position: sticky;
      top: 0;
      z-index: 1000;
    }

    .header-background {
      position: fixed;
      top: 0;
      z-index: 1000;
    }

    .hidden-menu {
      position: fixed;
      top: 79px;
      width: 100vw;
      height: calc(100vh - 79px);
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1000;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;

      .header-menu-item {
        color: white;
        min-width: 270px;
        text-align: center;

        &:hover {
          background: #202020;
          color: white;
        }
      }

      .active {
        color: #ffa500!important;

        &:hover {
          background: #729fff23;
        }
      }
    }

    .header {
      padding: 0 36px 0 24px;
      justify-content: space-between;
      &-menu {
        display: none!important;
      }

      &-hamburger {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .anticon {
          color: #FFFFFF!important;

          &:nth-child(2) {
            margin-top: -14px;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      &-user {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}


.showfer-admin {
  width: 100vw;

  .body {
    width: 100vw;
  }

  .header {
    width: 100vw;
    display: flex;
    background: #0a0909e5;
    box-shadow: 0 3px 6px #00000029;
    opacity: 1;
    height: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

    &-home-link {
      width: 200px;
      height: 100%;
      min-width: 200px;
      margin: 0 18px 0 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-logo {
      width: 100%;
    }

    &-menu {
      display: flex;
      width: calc(100% - 116px);
      align-items: center;

      &-left {
        display: flex;
        width: 40%;
        text-align: left;
        gap: 8px;
      }

      &-right {
        display: flex;
        gap: 8px;
        width: 55%;
        justify-content: flex-end;
      }

      &-item {
        font-size: 18px;
        color: #FFFFFF;
        padding: 10px 16px;
        position: relative;
        border-radius: 999px;


        &:hover {
          background: rgb(32, 32, 32);
        }
        &.without-bg {
          background: none;
        }

        &.active {
          color: rgb(0, 111, 238);
          font-weight: 700;
        }
        &.dropdown {
          .image-block {
            //copy from new front
            display: flex;
            --tw-border-opacity: 1;
            --background: #0a0a0a;
            --heroui-default: 240 5.26% 26.08%;
            --tw-ring-offset-color: var(--background);
            --tw-ring-offset-width: 2px;
            --tw-ring-opacity: 1;
            --tw-shadow: 0 0 #0000;
            --tw-bg-opacity: 1;
            --tw-ring-color: hsl(240 5.26% 26.08%/1);
            --tw-ring-offset-shadow: 0 0 0 2px #0a0a0a;
            --tw-ring-shadow: 0 0 0 calc(2px + 2px) hsl(240 5.26% 26.08%/1);
            box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow);
            background-color: hsl(var(--heroui-default)/var(--heroui-default-opacity,var(--tw-bg-opacity)));
            border-radius: 9999px;

          }
          .title {
            padding-right: 5px;
          }
          img {
            width: 32px;
            justify-content: center;
            display: flex;
          }
        }
      }
    }
  }
}

.ant-table-filter-trigger.active {
  color: var(--blue-color);
}

.ant-pagination-item {
  transition: .3s ease-out;

  &:hover {
    border-color: var(--blue-color);

    a {
      color: var(--blue-color);
    }
  }

  &-active {
    border-color: var(--blue-color);

    a {
      color: var(--blue-color);
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--blue-color);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--blue-color);
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: none;
}

.ant-switch-checked {
  background-color: var(--blue-color);
}

.showfer-button {
  cursor: pointer;
  background: var(--border-color) 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border: none;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s ease-out;

  span {
    color: var(--black-color);
  }

  &:focus {
    background: var(--border-color) 0 0 no-repeat padding-box;
  }

  &:hover, &.primary-button {
    background: var(--blue-color) 0 0 no-repeat padding-box;
  }

  &:hover, &.primary-button, &.success-button, &.danger-button {
    box-shadow: inset 0 3px 8px #00000066, 0 3px 6px #00000029;
    color: var(--white-color);

    span {
      color: var(--white-color);
    }
  }

  &.success-button {
    background: var(--green-color) 0 0 no-repeat padding-box;
  }

  &.danger-button {
    background: var(--red-color) 0 0 no-repeat padding-box;
  }

  &.default-button {
    background: var(--white-color);

    &:hover {
      color: var(--black-color);

      span {
        color: var(--black-color);
      }
    }
  }

  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
    color: var(--black-color);
    background: var(--border-color) 0 0 no-repeat padding-box;
    box-shadow: none;

    span {
      color: rgba(62, 62, 62, 0.5);
    }
  }
}

.ant-dropdown-menu {
  background: #202020;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px 0;
  min-width: 200px;

  &-item {
    color: #FFFFFF;
    font-size: 16px;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    padding: 8px 16px;

    .header {
      &-menu {
        &-item {
          &-user {
            font-weight: 600;
            font-size: 18px
          }
          &-email {
            color: rgb(255 165 0/var(--tw-text-opacity,1))
          }
        }
      }
    }

    &:hover {
      background: #303030;
    }
  }
}
